export let yPos = 0;
export let pixelsPerScroll = 20;
export let scrollDirection = 0;

export function resetYPos() {
  yPos = 0;
  document.getElementById('thirdparty_litmus_screenimg').style.top = yPos + 'px';
}

export function doScrollDown(pos) {
  yPos -= pixelsPerScroll;
  if (yPos > 0) {
    yPos = 0;
  }
  pixelsPerScroll -= 1;
  if (yPos * -1 >= document.getElementById('thirdparty_litmus_screenimg').height - 372) {
    document.getElementById('thirdparty_litmus_screenimg').style.top =
      (document.getElementById('thirdparty_litmus_screenimg').height - 372) * -1 + 'px';
    yPos = (document.getElementById('thirdparty_litmus_screenimg').height - 372) * -1;
    scrollDirection = 0;
    return;
  } else if (yPos <= 0) {
    document.getElementById('thirdparty_litmus_screenimg').style.top = yPos + 'px';
  }
  if (yPos > pos) {
    if (pixelsPerScroll < 8) {
      pixelsPerScroll = 2;
    }
    setTimeout(() => doScrollDown(pos), 20);
  } else {
    scrollDirection = 0;
  }
}

export function doScrollUp(pos) {
  yPos += pixelsPerScroll;
  pixelsPerScroll -= 1;
  if (yPos <= 0) {
    document.getElementById('thirdparty_litmus_screenimg').style.top = yPos + 'px';
  } else {
    yPos = 0;
    document.getElementById('thirdparty_litmus_screenimg').style.top = yPos + 'px';
    scrollDirection = 0;
    return;
  }
  if (yPos < pos) {
    if (pixelsPerScroll < 8) {
      pixelsPerScroll = 2;
    }
    setTimeout(() => doScrollUp(pos), 20);
  } else {
    scrollDirection = 0;
  }
}

export function doScrollDownBy(pixels) {
  if (scrollDirection == 0) {
    scrollDirection = -1;
    pixelsPerScroll = 20;
    doScrollDown(yPos - pixels);
  }
}

export function doScrollUpBy(pixels) {
  if (scrollDirection == 0) {
    scrollDirection = 1;
    pixelsPerScroll = 20;
    doScrollUp(yPos + pixels);
  }
}

export function scrollUp() {
  doScrollUpBy(200);
}

export function scrollDown() {
  doScrollDownBy(200);
}

export function onKeyDown(e) {
  if (!document.getElementById('thirdparty_litmus_screenimg')) {
    return;
  }
  var keycode;
  if (window.event) keycode = window.event.keyCode;
  else if (e) keycode = e.which;
  switch (keycode) {
    // UP
    case 38:
      scrollUp();
      return false;
    // DOWN
    case 40:
      scrollDown();
      return false;
  }
  return true;
}

document.onkeydown = onKeyDown;
