import { ClickLoadingIndicator, FullScreenDevicePreview } from '../../components';
import type { DevicePreview, DevicePreviewClient } from '../../types/device-preview';

import type { LitmusDeviceIds } from 'src/services/device-casings/LitmusDeviceIds';
import React from 'react';
import ThumbnailCard from '../../components/Thumbnail/ThumbnailCard';
import { getDeviceDetails } from '../../services/device-casings/DeviceDetails';
import { useState } from 'react';

interface EmailClientsProps {
  clients: DevicePreview['emailClients'] | [];
  group: string | undefined;
  loading: boolean;
}

const EmailClients: React.FC<EmailClientsProps> = ({ clients, group }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState<DevicePreviewClient | null>(null);

  const isMobile = group === 'Mobile/Tablet';
  const onThumbnailClick = (item: DevicePreviewClient) => {
    setPreviewItem(item);
    setIsPreviewOpen(true);
  };

  const onFullscreenClose = () => {
    setIsPreviewOpen(false);
    setPreviewItem(null);
  };
  return (
    <>
      {isPreviewOpen && previewItem && (
        <FullScreenDevicePreview
          name={`${previewItem.name}, ${previewItem.platform}` }
          fullUrl={previewItem.full}
          onClose={onFullscreenClose}
          deviceDetails={isMobile ? getDeviceDetails(previewItem.id as LitmusDeviceIds) : undefined}
        />
      )}
      <li style={{ flex: '0 0 100%', padding: '10px' }}>
        <h2
          data-testid="platform-group"
          className="text-xl font-bold mb-4 theme-blue-text-color"
        >
          {group?.toUpperCase()} EMAIL CLIENTS
        </h2>
        <ul>
          {clients.map((item) => (
            <li
              data-testid="email-client"
              key={item.id}
              style={{ display: 'inline-block', margin: '10px' }}
            >
              <ThumbnailCard
                onClick={() => onThumbnailClick(item)}
                thumbnailUrl={item.thumb}
                text={`${item.name}, ${item.platform}`}
                showLoader={!isPreviewOpen}
              />
            </li>
          ))}
        </ul>
      </li>
    </>
  );
};

export const InboxPreview: React.FC<{ devicePreviews: DevicePreview | null; loading: boolean }> = ({
  devicePreviews,
  loading,
}) => {
  const groupedClients = devicePreviews?.emailClients.reduce(
    (groups, item) => {
      const group = item.group || 'Other';
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group]?.push(item);
      return groups;
    },
    {} as Record<string, DevicePreview['emailClients']>,
  );

  return (
    <div className="device-preview-container bg-gray-200">
      <ClickLoadingIndicator
        isLoading={loading}
        type={'page'}
      />
      <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
        {groupedClients &&
          Object.entries(groupedClients).map(([group, clients]) => (
            <EmailClients
              key={group}
              clients={clients}
              group={group}
              loading={loading}
            />
          ))}
      </ul>
    </div>
  );
};
