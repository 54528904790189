import './Input.css';

import React from 'react';

interface InputProps {
  value: string;
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: string | null;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({ value, onValueChange, error, disabled = false }) => {
  return (
    <div className="">
      <input
        disabled={disabled}
        data-testid="from-address"
        role="textbox"
        type="text"
        value={value}
        onChange={onValueChange}
        className={`md:w-80 h-9 px-4 py-2 bg-white p-2 border focus-visible:border-sky-500 ${error ? 'border-red-500' : ' border-slate-400'}`}
      />
      {error && <p className="text-red-500 absolute">{error}</p>}
    </div>
  );
};

export { Input };
